import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import { Component, Watch } from "vue-property-decorator";
import Guid from "@/shared/helpers/guid-helper";
import { stringify } from "query-string";
import AppConsts from "@/shared/application/nucleus";
import Swal from "sweetalert2";

import VueTrix from "vue-trix";

@Component({ components: { VueTrix } })
export default class PaymentTrackingListComponent extends NucleusComponentBase {
  refs = this.$refs as any;
  allPermissions: IPermissionDto[] = [];
  errors: INameValueDto[] = [];
  loading = true;
  dialog = false;
  formTitle = "";
  options = {};
  search = "";
  selectAll = false;
  isAdmin = false;
  dataLoaded = false;
  selectedPaymentTrackId = "0";
  selectedItem = {
    newTvCommissionPercentage: "",
  };
  summary = {
    total: 0,
    payed: 0,
    waiting: 0,
  };
  paymentDate = "";
  hidePaymentDate = false;
  newTvCommissionString = "";
  invoiceSignMailDialog = false;

  invoiceSignMailInvoiceId = "";
  invoiceSignMailContent = "";
  isCanShowImage = false;
  selectedStatusFilter = "";
  awaitingSearch = false;
  companies = [] as ICompanyListOutput[];
  selectedCompanyFilter = "00000000-0000-0000-0000-000000000000";

  get headers() {
    return [
      { text: this.$t("InvoiceDate"), value: "invoicePeriod" },
      { text: this.$t("LastAmount"), value: "paymentAmount" },
      { text: this.$t("Status"), value: "status" },
      { text: "Marka Adı", value: "companyName" },
      { text: this.$t("Detay"), value: "action", sortable: false },
    ];
  }
  get adminHeaders() {
    return [
      { text: this.$t("Adı Soyadı"), value: "fullName" },
      { text: this.$t("Trendyol Id"), value: "trendyolUserName" },
      { text: this.$t("InvoiceDate"), value: "invoicePeriod" },
      { text: this.$t("InvoiceDate"), value: "invoiceId" },
      { text: this.$t("LastAmount"), value: "paymentAmount" },
      { text: this.$t("Status"), value: "status" },
      { text: this.$t("Image"), value: "image" },
      { text: "Marka Adı", value: "companyName" },
      { text: this.$t("Detay"), value: "action", sortable: false },
    ];
  }

  goToHome() {
    let routeData = this.$router.resolve({
      path: "/admin/home",
    });
    window.location.href = routeData.href;
  }
  get adminStatus() {
    return [
      { text: this.$t("MustBeUploadInvoice"), value: "0" },
      { text: this.$t("Kesildi"), value: "1" },
      { text: this.$t("Gönderildi"), value: "2" },
      { text: this.$t("İmzalandı"), value: "3" },
      { text: this.$t("Ödendi"), value: "4" },
      { text: "Sonraki Aya Devretmiştir", value: "5" },
      { text: "Revize Talep Edildi", value: "6" },
      { text: "Revize Geldi", value: "7" },
      { text: "İmza Onaylandı", value: "8" },
      { text: "Fatura Kesiliyor (Şirket)", value: "9" },
    ];
  }
  get memberStatus() {
    return [
      { text: this.$t("-"), value: "1" },
      { text: this.$t("PleaseSendDocument"), value: "2" },
      { text: this.$t("UploadCompleted"), value: "3" },
      { text: this.$t("Ödendi"), value: "4" },
      { text: "Sonraki Aya Devretmiştir", value: "5" },
      { text: this.$t("PleaseSendDocument"), value: "6" },
      { text: this.$t("UploadCompleted"), value: "7" },
      { text: this.$t("UploadCompleted"), value: "8" },
      { text: "Fatura Kesiliyor (Şirket)", value: "9" },
    ];
  }

  createOrUpdatePaymentTrackingInput = {
    paymentTracking: {} as IPaymentTrackingDto,
  } as ICreateOrUpdatePaymentTrackingInput;

  pagedListOfPaymentTrackingListDto: IPagedList<IPaymentTrackingListOutput> = {
    totalCount: 0,
    items: [],
  };

  @Watch("options")
  onPaginationChanged() {
    if (this.dataLoaded) {
      this.getPaymentTrackings();
    }
  }

  @Watch("search")
  onSearchChanged() {
    if (!this.awaitingSearch) {
      setTimeout(() => {
        if (this.dataLoaded) {
          this.getPaymentTrackings();
        }
      }, 2000); // 1 sec delay
    }
    this.awaitingSearch = true;
  }
  @Watch("selectedStatusFilter")
  onSelectedStatusChanged() {
    if (this.dataLoaded) {
      this.getPaymentTrackings();
    }
  }

  @Watch("selectedCompanyFilter")
  onCompanyIdChanged() {
    this.getPaymentTrackings();
  }

  mounted() {
    console.log("mounted");
    this.getPaymentTrackings();
    this.getPaymentDate();
  }

  editPaymentTracking(id: string) {
    this.errors = [];
    this.nucleusService
      .get<IGetPaymentTrackingForCreateOrUpdateOutput>(
        "/api/paymentTrackings/" + (id ? id : Guid.empty)
      )
      .then((response) => {
        const result =
          response.content as IGetPaymentTrackingForCreateOrUpdateOutput;
        this.createOrUpdatePaymentTrackingInput = {
          paymentTracking: result.paymentTracking,
        };
        this.dialog = true;
      });
  }

  deletePaymentTracking(id: string) {
    this.swalConfirm(this.$t("AreYouSureToDelete").toString()).then(
      (result) => {
        if (result.value) {
          this.nucleusService
            .delete("/api/paymentTrackings?id=" + id)
            .then((response) => {
              if (!response.isError) {
                this.swalToast(
                  2000,
                  "success",
                  this.$t("Successful").toString()
                );
                this.getPaymentTrackings();
              } else {
                var errorText = "";
                response.errors.forEach((error) => {
                  errorText += this.$t(error.name) + "<br>";
                });

                this.swalAlert("error", errorText);
              }
            });
        }
      }
    );
  }

  save(id: any) {
    debugger;
    this.createOrUpdatePaymentTrackingInput.paymentTracking.id = id;
    this.errors = [];
    this.nucleusService
      .postOrPut<void>(
        "/api/paymentTrackings",
        this
          .createOrUpdatePaymentTrackingInput as ICreateOrUpdatePaymentTrackingInput,
        id
      )
      .then((response) => {
        debugger;
        if (!response.isError) {
          this.swalToast(2000, "success", this.$t("Successful").toString());
          this.dialog = false;
          this.getPaymentTrackings();
        } else {
          this.errors = response.errors;
        }
      });
  }
  deleteMemberUpload(id: any) {
    debugger;
    this.createOrUpdatePaymentTrackingInput.paymentTracking.id = id;
    this.errors = [];
    this.nucleusService
      .get<void>(
        "/api/paymentTrackings/DeleteMemberUpload?paymentTrackingId=" + id
      )
      .then((response) => {
        debugger;
        if (!response.isError) {
          this.swalToast(2000, "success", this.$t("Successful").toString());
          this.dialog = false;
          this.getPaymentTrackings();
        } else {
          this.errors = response.errors;
        }
      });
  }
  AcceptMemberInvoice(id: any) {
    debugger;
    this.createOrUpdatePaymentTrackingInput.paymentTracking.id = id;
    this.errors = [];
    this.nucleusService
      .get<void>(
        "/api/paymentTrackings/AcceptMemberInvoice?paymentTrackingId=" + id
      )
      .then((response) => {
        debugger;
        if (!response.isError) {
          this.swalToast(2000, "success", this.$t("Successful").toString());
          this.dialog = false;
          this.getPaymentTrackings();
        } else {
          this.errors = response.errors;
        }
      });
  }
  deleteMemberUploadSelf(id: any) {
    debugger;
    this.createOrUpdatePaymentTrackingInput.paymentTracking.id = id;
    this.errors = [];
    this.nucleusService
      .get<void>(
        "/api/paymentTrackings/DeleteMemberUploadSelf?paymentTrackingId=" + id
      )
      .then((response) => {
        debugger;
        if (!response.isError) {
          this.swalToast(2000, "success", this.$t("Successful").toString());
          this.dialog = false;
          this.getPaymentTrackings();
        } else {
          this.errors = response.errors;
        }
      });
  }
  deleteAdminUpload(id: any) {
    debugger;
    this.createOrUpdatePaymentTrackingInput.paymentTracking.id = id;
    this.errors = [];
    this.nucleusService
      .get<void>(
        "/api/paymentTrackings/DeleteAdminUpload?paymentTrackingId=" + id
      )
      .then((response) => {
        debugger;
        if (!response.isError) {
          this.swalToast(2000, "success", this.$t("Successful").toString());
          this.dialog = false;
          this.getPaymentTrackings();
        } else {
          this.errors = response.errors;
        }
      });
  }

  getPaymentTrackings() {
    console.log("getPaymentTrackings");
    this.formTitle = this.authStore.getTokenData().sub;
    this.isAdmin = this.nucleus.auth.isGranted("Permissions_Administration");
    this.loading = true;
    const { sortBy, sortDesc, page, itemsPerPage }: any = this.options;
    const paymentTrackingListInput: IPagedListInput | any = {
      filter: this.search,
      pageIndex: 0,
      pageSize: itemsPerPage == -1 ? 100000000 : itemsPerPage,
      userId: this.isAdmin ? null : this.authStore.getTokenData().jti,
      statusFilter: this.selectedStatusFilter,
    };

    const query = "?" + this.queryString.stringify(paymentTrackingListInput);
    this.nucleusService
      .get<IPagedList<IPaymentTrackingListOutput>>(
        "/api/paymentTrackings" + query,
        false
      )
      .then((response) => {
        console.log(response);
        this.awaitingSearch = false;
        this.pagedListOfPaymentTrackingListDto =
          response.content as IPagedList<IPaymentTrackingListOutput>;
        this.loading = false;
        this.dataLoaded = true;
        this.showOrHidePaymentDate();
        this.calculateSummary();
      });
  }
  showOrHidePaymentDate() {
    if (this.pagedListOfPaymentTrackingListDto.items.length > 0) {
      if (this.pagedListOfPaymentTrackingListDto.items[0].status == 5) {
        this.hidePaymentDate = true;
      }
    }
  }
  getPaymentDate() {
    this.nucleusService
      .get<string>("/api/definitions", false)
      .then((response) => {
        this.paymentDate = response.content;
      });
  }
  calculateSummary() {
    console.log("calculateSummary");
    var notpayed = 0;
    this.pagedListOfPaymentTrackingListDto.items
      .filter((x) => x.status == 2 || x.status == 3 || x.status == 0)
      .map((x) => (notpayed += x.paymentAmount));

    var payed = 0;

    this.pagedListOfPaymentTrackingListDto.items
      .filter((x) => x.status == 4)
      .map((x) => (payed += x.paymentAmount));
    this.summary.waiting = notpayed;
    this.summary.payed = payed;
    this.summary.total = notpayed + payed;
    console.log(this.summary);
  }

  downloadPaymentTrackingExcel() {
    Swal.fire({
      title: "Fatura Tarihi",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "İndir",
      showLoaderOnConfirm: true,
      preConfirm: (date) => {
        if (date == "") {
          alert("Lütfen InvoiceId Giriniz");
          return;
        }

        var downloadPath = "/api/paymentTrackings/getTrackingExcel/";

        const link = document.createElement("a");
        link.href =
          AppConsts.baseApiUrl +
          downloadPath +
          date +
          "?companyId=" +
          this.selectedCompanyFilter;
        link.click();
        URL.revokeObjectURL(link.href);
        this.swalToast(2000, "success", this.$t("Successful").toString());
        this.dialog = false;
      },
    });
  }

  openFileUploadDialog(id: any) {
    this.selectedPaymentTrackId = id;
    var uploaderInput = this.$refs.uploader as HTMLElement;
    uploaderInput.click();
  }
  onFileChanged(e: any) {
    if (this.selectedPaymentTrackId == "0") {
      alert("Lütfen Yüklenecek Fatura bilgisini seçiniz");
      return;
    }

    var files = e.target.files[0];

    if (files != undefined) {
      this.checkSize(10485760, files);

      this.errors = [];

      var formData = new FormData();
      formData.append("paymentTrackingId", this.selectedPaymentTrackId);
      formData.append("files", files);
      this.nucleusService
        .postExcel<void>(
          this.nucleus.auth.isGranted("Permissions_Administration")
            ? "/api/paymentTrackings/UploadInvoicePhoto"
            : "/api/paymentTrackings/UploadMemberInvoicePhoto",
          formData
        )
        .then((response) => {
          if (!response.isError) {
            this.swalToast(2000, "success", this.$t("Successful").toString());
            this.dialog = false;
            this.getPaymentTrackings();
          } else {
            this.errors = response.errors;
          }
        });
    }
  }
  downloadInvoicePhoto(id: any) {
    var downloadPath = this.nucleus.auth.isGranted("Permissions_Administration")
      ? "/api/paymentTrackings/DownloadMemberInvoicePhoto/"
      : "/api/paymentTrackings/DownloadInvoicePhoto/";
    const link = document.createElement("a");
    link.href = AppConsts.baseApiUrl + downloadPath + id;
    link.click();
    URL.revokeObjectURL(link.href);
    this.swalToast(2000, "success", this.$t("Successful").toString());
    this.dialog = false;
  }
  downloadAdminInvoicePhoto(id: any) {
    var downloadPath = "/api/paymentTrackings/DownloadInvoicePhoto/";
    const link = document.createElement("a");
    link.href = AppConsts.baseApiUrl + downloadPath + id;
    link.click();
    URL.revokeObjectURL(link.href);
    this.swalToast(2000, "success", this.$t("Successful").toString());
    this.dialog = false;
  }
  getDetail(item: any) {
    this.dialog = true;
    this.selectedItem = item;
    this.newTvCommissionString =
      "NewTv Komisyon Tutarı (%" +
      this.selectedItem.newTvCommissionPercentage +
      ")";
  }
  uploadPdf() {
    var uploaderInput = this.$refs.uploadMultiPdf as HTMLElement;
    uploaderInput.click();
  }
  onMultiFileChanged(e: any) {
    var files = e.target.files;
    debugger;
    if (files != undefined) {
      this.errors = [];

      var formData = new FormData();
      //formData.append("paymentTrackingId", this.selectedPaymentTrackId);
      for (var i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }

      debugger;
      this.nucleusService
        .postExcel<void>(
          "/api/paymentTrackings/UploadInvoicePhotos",

          formData
        )
        .then((response) => {
          if (!response.isError) {
            this.swalToast(2000, "success", this.$t("Successful").toString());
            this.dialog = false;

            this.getPaymentTrackings();
          } else {
            this.errors = response.errors;
            var errorString = "";
            this.errors.forEach(
              (x) =>
                (errorString +=
                  "<span style='color:red'>" +
                  x.name +
                  "</span> - " +
                  x.value +
                  "<br>")
            );
            this.swalAlert("Hata", errorString);
          }
        });
    }
  }
  checkSize(max_size: any, file: any) {
    debugger;

    if (file.size > max_size) {
      this.swalToastCenter(
        3000,
        "error",
        "Seçtiğiniz dosya en fazla " +
          max_size / 1024 / 1024 +
          "MB olabilir. Lütfen farklı bir dosya seçiniz"
      );
      return false;
    }

    return true;
  }
  updatePaymentTrackingForAll() {
    this.nucleusService
      .get<void>("/api/paymentTrackings/UpdatePaymentTrackingForAll")
      .then((response) => {
        if (!response.isError) {
          this.getPaymentTrackings();
        } else {
          this.errors = response.errors;
        }
      });
  }
  sendMailForNotSignedInvoice() {
    if (this.invoiceSignMailContent == "") {
      this.swalAlert("Uyarı", "Lütfen Mail İçeriği Giriniz");
      return;
    }
    if (this.invoiceSignMailInvoiceId == "") {
      this.swalAlert("Uyarı", "Lütfen Invoice Id Giriniz");
      return;
    }
    var data = {
      invoiceId: this.invoiceSignMailInvoiceId,
      mailContent: this.invoiceSignMailContent,
    };
    this.nucleusService
      .post<void>(
        "/api/paymentTrackings/SendMailForNotSignedInvoices?mailContent",
        data
      )
      .then((response) => {
        if (!response.isError) {
          this.swalToast(2000, "success", this.$t("Successful").toString());
        } else {
          this.errors = response.errors;
        }
      });
  }
  showImageInPopup(imageUrl: any) {
    this.swalAlert(
      "Resim",
      "<img src='" + imageUrl + "' width='500' heigth='500'/>"
    );
  }
  showImageMemberInPopup(imageUrl: any) {
    this.swalAlert(
      "Resim",
      "<img src='" + imageUrl + "' style='width:100% !important'/>"
    );
  }
  onExcelPayFileChanged(e: any) {
    var files = e.target.files[0];
    if (files != undefined) {
      this.errors = [];

      var formData = new FormData();
      formData.append("files", files);
      this.nucleusService
        .postExcel<void>(
          "/api/paymenttrackings/UpdatePaymentWithExcelForPayedStatus",
          formData
        )
        .then((response) => {
          if (!response.isError) {
            this.swalToast(2000, "success", this.$t("Successful").toString());
            this.dialog = false;
            this.getPaymentTrackings();
          } else {
            this.errors = response.errors;
          }
        });
    }
  }
  updatePayedStatusWithExcel() {
    var uploaderInput = this.$refs.uploadExcelPay as HTMLElement;
    uploaderInput.click();
  }
}
