import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import { Component } from "vue-property-decorator";

@Component
export default class Onboarding1Component extends NucleusComponentBase {
  openHome() {
    localStorage.setItem("onboardingShown", "true");
    let routeData = this.$router.resolve({
      path: "/admin/home",
    });
    window.location.href = routeData.href;
  }
}
