<template>
  <div class="divide-gray-200">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="accordion-item border-gray-200 m-3 border-2"
    >
      <button
        @click="toggle(index)"
        class="accordion-button py-4 px-6 w-full text-left flex justify-between items-center"
      >
        <span style="font-size: 13.51; font-weight: 700">{{ item.title }}</span>
        <span style="color: #711adf; font-size: 36px" v-if="isOpen(index)"
          >-</span
        >
        <span style="color: #711adf" v-else>+</span>
      </button>
      <div v-if="isOpen(index)" class="accordion-content px-6 py-4">
        <p style="color: #757575; font-weight: 400; font-size: 13px">
          {{ item.content }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Itemların açık/kapalı durumunu tutan array
      openIndexes: [],
      // Accordion içeriği
      items: [
        {
          title: "Influencerhub Nedir? Ne işe Yarar?",
          content:
            "InfluencerHub, Mercan Superapp içerisinde yer alan ve kullanıcıların Mercan’ı indirmeye olanak veren linki üretmesini sağlayan bir mini app'tir. Kullanıcılar ürettikleri linki arkadaşlarıyla ve ilgili mecralarda paylaşarak, uygulamanın indirilmesinde rol oynar ve katkı sağladığı her indirme için gelir elde eder.",
        },
        {
          title: "Uygulamaya Giriş İçin Profil Oluşturmam Gerekli mi?",
          content:
            "Mercan Superapp'in sahip olduğu Open ID teknolojisi ile Mercan içerisinde yer alan profil bilgileriniz InfluencerHub mini app'ine aktarılır. Bu sayede uygulamaya eforsuz ve güvenli bir şekilde giriş sağlamış olursunuz. Yapmanız gereken tek şey profil bilgilerinizi tamamlamak ve hak edişlerinizi almak için 'Profil' bölümünden IBAN bilgilerinizi eklemek olacaktır.",
        },
        {
          title: "Affiliate Nedir?",
          content:
            "Affiliate, bir kişinin veya kuruluşun, bir ürün veya hizmeti tanıtarak, başkalarının o ürün veya hizmeti satın almasını sağlaması ve bu işlem üzerinden komisyon kazanması sürecidir. InfluencerHub, kullanıcılarının Mercan uygulaması için oluşturduğu affiliate linkler üzerinden elde edilen indirmelerle para kazanmasını sağlar.",
        },
        {
          title: "Mercan Uygulamasını İndirdikçe Nasıl Kazanıyorum?",
          content:
            "InfluencerHub üzerinden oluşturduğunuz ve paylaştığınız affiliate link üzerinden yapılan her Mercan uygulaması indirilmesi, belirlenen bir komisyon oranı üzerinden sizin kazancınıza dönüşür. Bu kazanç, belirli bir dönem sonunda toplanır ve hesabınıza aktarılır.",
        },
        {
          title: "Ödemelerim Ne Zaman Yapılır?",
          content:
            "InfluencerHub, linkiniz üzerinden gerçekleşen indirmeleri takip eder. Kazandığınız hak edişleri 'Ödemeler' bölümünde güncel olarak görebilirsiniz. Ödemeler, her ayın belirlenen bir gününde, bir önceki ay içinde elde edilen toplam kazanç üzerinden yapılır. Tüm ödemeler, banka hesabınıza IBAN numaranız üzerinden aktarılır.",
        },
        {
          title: "Hangi Ödeme Yöntemlerini Kullanabilirim?",
          content:
            "Ödemeler, banka hesabınıza IBAN numaranız aracılığıyla yapılmaktadır. Bu yöntem, güvenli ve hızlı bir şekilde ödemelerinizi almanızı sağlar.",
        },
        // Diğer itemlar...
      ],
    };
  },
  methods: {
    toggle(index) {
      const isOpen = this.isOpen(index);
      if (isOpen) {
        this.openIndexes = this.openIndexes.filter((i) => i !== index);
      } else {
        this.openIndexes.push(index);
      }
    },
    isOpen(index) {
      return this.openIndexes.includes(index);
    },
  },
};
</script>

<style scoped>
/* Tailwind CSS kullanılarak gerekli stillendirmeler */
</style>
