import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import { Component } from "vue-property-decorator";

@Component
export default class OnboardingComponent extends NucleusComponentBase {
  openPage2() {
    let routeData = this.$router.resolve({
      path: "/onboarding/onboarding2",
    });
    window.location.href = routeData.href;
  }
}
