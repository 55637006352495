import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import { Component, Watch } from "vue-property-decorator";
import Guid from "@/shared/helpers/guid-helper";
import { TheMask } from "vue-the-mask";
import Swal from "sweetalert2";
import contractDialog from "./contract-dialog.vue";
@Component({ components: { contractDialog } })
export default class UserListComponent extends NucleusComponentBase {
  refs = this.$refs as any;
  loading = true;
  options = {};
  search = "";
  dialog = false;
  formTitle = "";
  errors: INameValueDto[] = [];
  allRoles: IRoleDto[] = [];
  isEdit = false;
  selectAll = false;

  createOrUpdateUserInput = {
    user: {} as IUserDto,
  } as ICreateOrUpdateUserInput;

  pagedListOfUserListDto: IPagedList<IPagedListInput> = {
    totalCount: 0,
    items: [],
  };
  goToHome() {
    let routeData = this.$router.resolve({
      path: "/admin/home",
    });
    window.location.href = routeData.href;
  }

  get bankNames() {
    return [
      { text: "Garanti Bankası", value: "Garanti Bankası" },
      { text: "Akbank", value: "Akbank" },
      { text: "İş Bankası", value: "İş Bankası" },
      { text: "Yapı Kredi", value: "Yapı Kredi" },
      { text: "Ziraat Bankası", value: "Ziraat Bankası" },
      { text: "Vakıfbank", value: "Vakıfbank" },
      { text: "ING Bank", value: "ING Bank" },
      { text: "Fiba Banka", value: "Fiba Banka" },
      { text: "Halkbank", value: "Halkbank" },
      { text: "Denizbank", value: "Denizbank" },
      { text: "TEB", value: "TEB" },
      { text: "PTT Bank", value: "PTT Bank" },
      { text: "Kuveyt Türk", value: "Kuveyt Türk" },
      { text: "Albaraka", value: "Albaraka" },
      { text: "Diğer (yazınız)", value: "Diğer (yazınız)" },
    ];
  }
  get trendyolStatus() {
    return [
      { text: "Yeni başlıyorum, birşey yapmadım", value: 1 },
      { text: "Trendyol'a başvurdum, onay bekliyorum", value: 2 },
      { text: "Trendyol onayladı ajans seçiyorum", value: 3 },
      { text: "Herşey tamam, başlıyorum", value: 4 },
      { text: "Paylaşım yapıyorum", value: 6 },
      { text: "Ayrılmak istiyorum", value: 5 },
    ];
  }

  mounted() {
    this.getProfileDetail(this.$route.query.id);
  }

  getProfileDetail(userId: any) {
    if (userId == undefined) {
      userId =
        this.authStore.getTokenData()[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
        ];
    }

    this.errors = [];
    this.nucleusService
      .get<IGetUserForCreateOrUpdateOutput>("/api/profile?id=" + userId)
      .then((response) => {
        const result = response.content as IGetUserForCreateOrUpdateOutput;
        this.allRoles = result.allRoles;
        this.createOrUpdateUserInput = {
          grantedRoleIds: result.grantedRoleIds,
          user: result.user,
        };

        if (this.createOrUpdateUserInput.user.ibanNumber == null) {
          this.createOrUpdateUserInput.user.ibanNumber = "TR";
        }

        this.formTitle = result.user.userName;
      });
  }

  save() {
    if (
      this.createOrUpdateUserInput.user.isHaveCompany &&
      this.createOrUpdateUserInput.user.companyType == ""
    ) {
      this.swalAlert("error", "Lütfen Şirket tipi seçiniz");
      return;
    }

    if (!(this.createOrUpdateUserInput.user.ibanNumber.length >= 32)) {
      this.swalAlert("error", "Lütfen Iban numarasını eksiksiz olarak giriniz");
      return;
    }

    if (!this.createOrUpdateUserInput.user.isHaveCompany) {
      if (!(this.createOrUpdateUserInput.user.identityNumber.length >= 11)) {
        this.swalAlert(
          "error",
          "Lütfen Tc Kimlik numarasını eksiksiz olarak giriniz"
        );
        return;
      }
    }

    if (this.refs.form.validate()) {
      this.errors = [];
      this.nucleusService
        .postOrPut<void>(
          "/api/profile",
          this.createOrUpdateUserInput as ICreateOrUpdateUserInput,
          this.createOrUpdateUserInput.user.id
        )
        .then((response) => {
          if (!response.isError) {
            this.swalToastCenter(
              3000,
              "success",
              this.$t("Successful").toString()
            );
            this.dialog = false;
            if (this.$route.query.id != undefined) {
              this.$router.push({ path: "/admin/user-list" });
            } else {
              this.getProfileDetail(this.$route.query.id);
            }
          } else {
            this.errors = response.errors;
            window.scrollTo(0, 0);
          }
        });
    }
  }

  showTrendyolPopup() {
    Swal.fire({
      title: "Trendyol Kayıtlı Influencer İsminiz Nedir? ",
      html: "Trendyol tarafından size gönderilen günlük raporlarda sol üst köşede yazan kısımdır. (Genelde isim soyisim şeklinde olmaktadır.)",
      confirmButtonText: "Kapat",
    });
  }
  isNumber(evt: any) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (
      (charCode > 31 && (charCode < 48 || charCode > 57)) ||
      charCode === 46
    ) {
      evt.preventDefault();
    } else {
      return true;
    }
  }

  resetCompanyCheck() {
    if (this.createOrUpdateUserInput.user.isHaveCompany) {
      this.createOrUpdateUserInput.user.identityNumber = "";
      this.createOrUpdateUserInput.user.bankAccountFullName = "";
    } else {
      this.createOrUpdateUserInput.user.companyName = "";
      this.createOrUpdateUserInput.user.taxNumber = "";
    }
  }

  lowerCaseTrendyolUserName() {
    this.createOrUpdateUserInput.user.trendyolUserName =
      this.createOrUpdateUserInput.user.trendyolUserName
        .toLowerCase()
        .replace(" ", "");
  }
  isLetterOrNumber(e: any) {
    let char = String.fromCharCode(e.keyCode); // Get the character
    if (/^[A-Za-z0-9]+$/.test(char)) return true;
    // Match with regex
    else e.preventDefault(); // If not match, don't add to input text
  }
}
