import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import { Component } from "vue-property-decorator";

@Component
export default class Onboarding1Component extends NucleusComponentBase {
  openPage3() {
    let routeData = this.$router.resolve({
      path: "/onboarding/onboarding3",
    });
    window.location.href = routeData.href;
  }
}
