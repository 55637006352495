import { Component } from "vue-property-decorator";
import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import Swal from "sweetalert2";
@Component
export default class LoginComponent extends NucleusComponentBase {
  public refs = this.$refs as any;

  public errors: INameValueDto[] = [];

  mounted() {
    // ?session_state=a0c53ef1-f505-400c-9ccd-a03f8e86b06b&code=39272089-a4c0-4309-ba0a-b897550a7e6c.a0c53ef1-f505-400c-9ccd-a03f8e86b06b.c10bf8a9-c755-4346-bbed-d6aaaa9fc75b
    var search = location.search.substring(1);
    var object = JSON.parse(
      '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
      function (key, value) {
        return key === "" ? value : decodeURIComponent(value);
      }
    );

    console.log(object);

    this.nucleusService
      .post<ILoginOutput>("/api/loginWithMercan", object.code)
      .then((response) => {
        if (!response.isError) {
          this.authStore.setToken(response.content.token);
          this.$router.push({ path: "/admin/home" });
        } else {
          this.errors = response.errors;
          Swal.fire({
            title: "Error!",
            text: response.errors[0].value,
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      });
  }
}
