import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import { Component } from "vue-property-decorator";
import Accordion from "./accordion.vue";
@Component({ components: { Accordion } })
export default class FaqComponent extends NucleusComponentBase {
  goToHome() {
    let routeData = this.$router.resolve({
      path: "/admin/home",
    });
    window.location.href = routeData.href;
  }
}
