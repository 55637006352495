import { Component } from "vue-property-decorator";
import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import Swal from "sweetalert2";
@Component
export default class LoginComponent extends NucleusComponentBase {
  public refs = this.$refs as any;
  public loginInput = {} as ILoginInput;
  public errors: INameValueDto[] = [];

  mounted() {
    var returnUrl = "https://mercan.fantaslink.com/account/mercan-login";
    window.location.href =
      `https://idp.mercanplatform.com/auth/realms/prd01/protocol/openid-connect/auth?client_id=fantaslink&redirect_uri=${returnUrl}&scope=openid&response_type=code&response_mode=query&nonce=` +
      this.makeid(7);
  }

  makeid(length: any) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
}
