import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import { Component } from "vue-property-decorator";

import { MetaInfo } from "vue-meta";

@Component({
  metaInfo(this: RedirectComponent): MetaInfo {
    return {
      title: this.metaValue.title,
      meta: [
        {
          vmid: "og:description",
          property: "og:description",
          content: this.metaValue.description,
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: this.metaValue.imageUrl,
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: window.location.href,
        },
        //twitter
        {
          vmid: "twitter:card",
          name: "twitter:card",
          content: this.metaValue.title,
        },
        {
          vmid: "twitter:image",
          name: "twitter:image",
          content: this.metaValue.imageUrl,
        },
      ],
    };
  },
})
export default class RedirectComponent extends NucleusComponentBase {
  // get props from route

  metaInfo(): MetaInfo {
    return {
      title: "Sayfa Başlığı",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "Sayfa Açıklaması",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: "Open Graph Başlık",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: "Open Graph Açıklaması",
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: "https://example.com/image.jpg",
        },
      ],
    };
  }

  url: string = this.$route.query.url as string;

  createOrUpdateStorageInput = {
    storage: {} as any,
  } as any;

  metaValue = {
    title: "Mercan uygulamasini indirin.",
    description: "Mercan uygulamasi ile islerinizi kolaylastirin.",
    imageUrl: "https://fantaslink.com/img/newtv.773c299c.png",
  };

  created() {
    console.log("Redirecting to: ", this.url);
    this.getStorageForPreview();
  }
  getStorageForPreview() {
    console.log("Getting storage for preview");
    var id = "eb77f8de-ebdd-4975-816a-9e3699c3a002";
    this.nucleusService.get<any>("/api/storage/" + id).then((response) => {
      const result = response.content as any;
      this.createOrUpdateStorageInput = {
        storage: result,
      };

      var json = JSON.parse(result.value);
      console.log("Json: ", json);

      json = JSON.parse(json);
      debugger;
      console.log(json.title);

      this.metaValue.title = json.title;
      this.metaValue.description = json.description;
      this.metaValue.imageUrl = json.imageUrl;

      this.$meta().refresh();

      if (this.url) {
        window.location.href = this.url;
      }
      console.log("Storage: ", this.metaValue);
    });
  }
}
