import Vue from "vue";
import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import { Component } from "vue-property-decorator";
import AppConsts from "@/shared/application/nucleus";
import Swal from "sweetalert2";
@Component
export default class TrendyolInfoComponent extends Vue {
  downloadTrendyolPdf(id: any) {
    var downloadPath = "/api/definitions/DownloadTrendyolPdf";
    const link = document.createElement("a");
    link.href = AppConsts.baseApiUrl + downloadPath;
    link.click();
    URL.revokeObjectURL(link.href);
    this.swalToast(2000, "success", this.$t("Successful").toString());
  }
  downloadTaxPdf(id: any) {
    var downloadPath = "/api/definitions/DownloadTaxPdf";
    const link = document.createElement("a");
    link.href = AppConsts.baseApiUrl + downloadPath;
    link.click();
    URL.revokeObjectURL(link.href);
    this.swalToast(2000, "success", this.$t("Successful").toString());
  }
  protected swalToast(duration: number, type: string, title: string) {
    Swal.fire({
      toast: true,
      position: "bottom-end",
      showConfirmButton: false,
      timer: duration,
      type,
      title,
    } as any);
  }
}
