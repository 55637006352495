import { appConst } from "@/settings";
import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import { title } from "process";
import Swal from "sweetalert2";
import { Component } from "vue-property-decorator";
var builder = require("utm-builder");

@Component
export default class HomeComponent extends NucleusComponentBase {
  loading = true;
  shortlink = "";
  openFantaslinkPopUp() {
    let routeData = this.$router.resolve({
      path: "/admin/fantaslink-info",
    });
    window.location.href = routeData.href;
  }
  formData = {
    longUrl: "",
    title: "",
    description: "",
    image: "",
  };
  name = "";

  openOnboarding() {
    if (localStorage.getItem("onboardingShown") != "true") {
      let routeData = this.$router.resolve({
        path: "/onboarding/onboarding1",
      });
      this.loading = false;
      // window.location.href = routeData.href;
      //      window.location.reload();

      this.$router.push("/onboarding/onboarding1");
    }
  }
  openFaq() {
    let routeData = this.$router.resolve({
      path: "/admin/faq",
    });
    window.location.href = routeData.href;
  }

  get mainMenuItems() {
    return [
      { icon: "mdi-account", text: this.$t("Profile"), link: "/admin/profile" },
      // {
      //   icon: "mdi-link",
      //   text: this.$t("Link Oluştur"),
      //   link: "/admin/link-creator",
      // },
      {
        icon: "mdi-gavel",
        text: "Yeni Kanun Hesap Bilgisi",
        link: "/admin/legislation",
      },
      {
        icon: "mdi-currency-usd",
        text: this.$t("PaymentTracking"),
        link: "/admin/payment-tracking-list",
      },
      // {
      //   icon: "mdi-ticket",
      //   text: this.$t("Ticket"),
      //   link: "/admin/ticket-list",
      // },

      {
        icon: "mdi-share-variant-outline",
        text: "Kampanya Raporu",
        link: "/admin/campaign",
      },

      { icon: "mdi-domain", text: this.$t("Kurumsal"), link: "/admin/info" },
      {
        icon: "mdi-chat-question",
        text: "Sık Sorulan Sorular",
        link: "/admin/faq",
      },
    ];
  }

  linkSource = {
    link: "",
    campaign: "",
    source: "",
    medium: "",
  };
  username = "";
  public beforeMount() {
    this.openOnboarding();
    this.getUserName();

    this.linkSource.medium = "affiliate";
  }

  getUserName() {
    this.loading = false;
    var id =
      this.authStore.getTokenData()[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
      ];
    console.log(id);
    this.nucleusService
      .get<IGetUserForCreateOrUpdateOutput>(
        "/api/profile/GetUserNameWithId?id=" + id
      )
      .then((response) => {
        const result = response.content as IGetUserForCreateOrUpdateOutput;
        this.username = result.user.userName;
        this.name = result.user.fullName.split(" ")[0];

        this.linkSource.source = "influencer_" + this.username.toLowerCase();
      });
  }
  openProfile() {
    let routeData = this.$router.resolve({
      path: "/admin/profile",
    });
    window.location.href = routeData.href;
  }
  openPayment() {
    let routeData = this.$router.resolve({
      path: "/admin/payment-tracking-list",
    });
    window.location.href = routeData.href;
  }

  createLink() {
    var message = "";

    this.createShortUrl();
  }
  copyLink() {
    console.log(this.shortlink);
    var combinedText = "";
    if (this.name == "") {
      combinedText = this.shortlink;
    } else {
      combinedText =
        `${this.name} seni Mercan Dünyası'na çağırıyor, ` + this.shortlink;
    }

    console.log("component", combinedText);
    navigator.clipboard.writeText(combinedText);

    this.swalToastTop(2000, "success", "Link Kopyalandı");
  }

  async createShortUrl() {
    var link = "https://app.adjust.com/1cz3rkel?creative=" + this.username;

    var redirectUrl =
      this.nucleus.baseClientUrl + "/redirect?url=" + encodeURIComponent(link);

    const apiEndpoint = "https://fnts.link/rest/v2/short-urls";
    const apiKey = "351031e1-65bb-49d9-ae01-9242eb1d76a1";

    console.log(redirectUrl);
    fetch(apiEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": apiKey,
      },
      body: JSON.stringify({
        longUrl: redirectUrl,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.shortlink = data.shortUrl;
      })
      .catch((error) => {
        console.error("API isteğinde hata:", error);
        alert("URL oluşturma başarısız!");
      });
  }
}
